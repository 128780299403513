<template>
  <v-row>
    <v-col
      cols="12"
      class="text-right pb-0 mt-0"
    >
      <v-btn
        x-small
        elevation="10"
        class="text-xs font-weight-semibold"
        color="primary darken-2"
        :to="{ name: 'savings.clients_create_loan' }"
      >
        <font-awesome-icon
          icon="fa-solid fa-plus"
          class="pe-1"
        />
        Create a Loan
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-row class="mt-2 mx-2 pt-0">
          <v-col
            cols="12"
            class="px-4 pt-2 mt-1 mb-0 pb-0"
          >
            <v-select
              v-model="selectedLoanFilter"
              outlined
              dense
              :items="loan_filter"
              label="Filter Loans By"
            ></v-select>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-left mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedStartDate"
              dense
              type="date"
              label="Start Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-right mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedEndDate"
              dense
              type="date"
              label="End Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
            xs="2"
            class="text-right mt-0 pt-0"
          >
            <spinner v-if="loading" class="text-right"></spinner>
            <v-btn
              v-else
              icon small
              class="primary"
              @click="getLoans()"
            >
              <font-awesome-icon icon="fa-solid fa-search"/>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-title class="mt-0 pt-0">
          <span class="me-3">({{ requestedLoans.length }}) List Of Loans</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <h3 class="ml-3">Total Cash Saved: {{ savings_total }} F CFA  </h3>
        <v-data-table
          :headers="headers"
          :items="requestedLoans"
          item-key="id"
          class="table-rounded"
          :items-per-page="10"
          disable-sort
          :search="search"
        >
          <!-- name -->
          <template #[`item.name`]="{item}">
            <router-link :to="'/savings/clients/loan/'+item.id" >
              <div class="d-flex flex-column" style="cursor: pointer">
                <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.username }}: {{ item.name }}</span>
                <small v-if="item.target_date && item.target_amount">Type: <b>Date & Amount</b> at {{ item.percentage_completed }} Completion</small>
                <small v-else-if="item.target_amount">Type: <b>Amount</b> at {{ item.percentage_completed }} Completion</small>
                <small v-else-if="item.target_date">Type: <b>Date</b> at {{ item.percentage_completed }} Completion</small>
                <small v-else>Type: <b>Undefined</b>  at --- Completion</small>
              </div>
            </router-link>
          </template>
          <template #[`item.saved_amount`]="{item}">
            {{ item.saved_amount }} F CFA
          </template>

          <template #[`item.target_amount`]="{item}">
            {{ item.target_amount }} F CFA - {{ item.target_date }}
          </template>

          <!-- status -->
          <template #[`item.status`]="{item}">
            <v-chip
              small
              @click.stop="setDialog=true"
              @click="changeSwitch(item.id)"
              :color="statusColor[item.status]"
              class="font-weight-medium"
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template #[`item.get_date_str`]="{item}">
            {{ item.get_date_str }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-dialog width="500px" v-model="setDialog"> 
        <v-card> 
          <v-card-title class=" white--text">{{ dialogTitle }}</v-card-title> 
          <v-card-text class="black--text pt-2">
            <span v-html="transactionDetails"></span>

            <v-switch label="Validated" color="success" inset v-model="validatedState" @click="changeValidateState()"></v-switch>
            <v-switch label="Cancelled" color="error" inset v-model="cancelledState" @click="changeCancelledState()"></v-switch>
            <v-switch label="Pending" color="primary" inset v-model="pendingState" @click="changePendingState()"></v-switch>
            <v-btn color="primary" @click="changeLoanState()" > Change </v-btn> 
          </v-card-text> 
          <v-divider></v-divider> 
          <v-card-actions> 
            <v-spacer></v-spacer> 
            <v-btn color="success" text @click="setDialog = false" > Close </v-btn> 
          </v-card-actions> 
        </v-card> 
    </v-dialog> 
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Ongoing: 'primary',
      Completed: 'success',
      Inactivated: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Amount Saved', value: 'saved_amount' },
        { text: 'Target', value: 'target_amount' },
        { text: 'Status', value: 'status' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      selectedLoanFilter: "All",
      loan_filter: ["All", "Ongoing", "Completed", "Inactivated"],
      requestedLoans: [],
      statusColor,
      search: '',
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,
      savings_total: '',

      setDialog: false,
      selectedLoan: null,
      dialogTitle: "Set Loan Status",
      transactionDetails: "",
      pendingState: false,
      validatedState: false,
      cancelledState: false,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Savings | List of Loans"
  },

  methods:{
    changeSwitch(loan_id){
      axios
        .get('/api/v1/manager/get/client/loan/'+loan_id+'/')
        .then(response => {
          const username = response.data[0]["username"]
          const saved_amount = response.data[0]["saved_amount"]
          this.selectedLoan = loan_id
          const loan = response.data[0]["loan_name"]
          const transaction_operator = response.data[0]["transaction_operator"]

          this.transactionDetails = "Change the state of this requested loan <b>'"+loan+"'</b> by <b>'"+username+"'</b>"
          console.log(response.data)

          this.pendingState = response.data[0]["pending_state"]
          this.validatedState = response.data[0]["validated_state"]
          this.cancelledState = response.data[0]["cancelled_state"]
        })
        .catch(error => {
          this.setDialog = false
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    changeValidateState(){
      if(this.validatedState){
        this.pendingState = false
        this.cancelledState = false
      }
    },
    
    changeCancelledState(){
      if(this.cancelledState){
        this.validatedState = false
        this.pendingState = false
      }
    },

    changePendingState(){
      if(this.pendingState){
        this.validatedState = false
        this.cancelledState = false
      }
    },

    changeLoanState(){
      if (!this.pendingState && !this.validatedState && !this.cancelledState){
        this.$store.commit('setSnackBarMessage', "Error: Please select a state")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
          
      }
      else{
        let formData = new FormData()
        formData.append('pendingState', this.pendingState)
        formData.append('validatedState', this.validatedState)
        formData.append('cancelledState', this.cancelledState)

        axios
          .post('/api/v1/manager/change/loan/'+this.selectedLoan+'/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => {
            this.$store.commit('setSnackBarMessage', response.data[0].text)
            this.$store.commit('setSnackBarColor', "primary darken-4")
            this.$store.commit('activateSnackBar', true)
            this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            this.getLoans()
            this.setDialog = false
          })
          .catch(error => {
            this.setDialog = false
            if (error.response){
              this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
            }else{
              this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
            }
          })
      }
    },

    async getLoans(){
      if (this.selectedLoanFilter === ''){
        this.selectedLoanFilter = 'All'
      }
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }
      this.loading = true
      await axios
        .get('/api/v1/manager/get/loans/'+this.selectedLoanFilter+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this.requestedLoans = response.data["loans"]  // get the data and fill into loans
          this.savings_total = response.data["total_saved"]  // get the data and fill into loans
          console.log(this.requestedLoans)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },
  },
}
</script>
